<template>
  <div class="public-box">
    <el-form :inline="true">
      <el-form-item>
        <MyInput v-model="form.merchants_name" placeholder="请输入商户姓名">
          <template slot="pre">商户姓名:</template>
        </MyInput>
      </el-form-item>
      <el-form-item>
        <MyInput
          class="offset"
          v-model="form.merchants_number"
          placeholder="请输入商户号"
        >
          <template slot="pre">商户号:</template>
        </MyInput>
      </el-form-item>
      <el-form-item>
        <MyInput
          class="offset"
          v-model="form.merchants_stall_number"
          placeholder="请输入商铺号"
        >
          <template slot="pre">商铺号:</template>
        </MyInput>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          class="offset"
          v-model="date"
          @change="changeDate"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          type="daterange"
          start-placeholder="缴费时间"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <MyButton class="offset" @click="getList">
          <span>搜索</span>
          <template slot="preImage">
            <img src="../../../unit/img/search.png" alt />
          </template>
        </MyButton>
      </el-form-item>
    </el-form>
    <el-form :inline="true">
      <el-form-item>
        <MyButton :accessId="37311" @click="setImport(0)">
          <span>批量商铺数据初始化</span>
          <template slot="preImage">
            <img src="../../../unit/img/st.png" alt />
          </template>
        </MyButton>
      </el-form-item>
      <el-form-item>
        <MyButton class="offset" :accessId="31323" @click="merchantStallInit">
          <span>商户商铺数据初始化</span>
          <template slot="preImage">
            <img src="../../../unit/img/st.png" alt />
          </template>
        </MyButton>
      </el-form-item>
      <el-form-item>
        <MyButton
          class="offset"
          :accessId="31323"
          @click="merchantStallCasualInit"
        >
          <span>商户商铺临时录入</span>
          <template slot="preImage">
            <img src="../../../unit/img/st.png" alt />
          </template>
        </MyButton>
      </el-form-item>
      <el-form-item>
        <MyButton class="offset" :accessId="71854" @click="setImport(1)">
          <span>手工抄表数据批量导入</span>
          <template slot="preImage">
            <img src="../../../unit/img/st.png" alt />
          </template>
        </MyButton>
      </el-form-item>
      <el-form-item>
        <MyButton :accessId="54370" class="offset" @click="exported">
          导出
          <template slot="preImage">
            <img src="../../../unit/img/down.png" alt />
          </template>
        </MyButton>
      </el-form-item>
      <el-form-item>
        <MyButton class="offset" @click="gourl('chargeoff')">
          出账记录
          <template slot="preImage">
            <img src="../../../assets/img/b2.png" alt />
          </template>
        </MyButton>
      </el-form-item>
      <el-form-item>
        <div class="offset ysall">
          <span>应收总金额:</span>
          <span>{{
            (dailyBillOverview && dailyBillOverview.total_price) || 0
          }}</span>
        </div>
      </el-form-item>
    </el-form>
    <div class="public-table">
      <el-table
        :data="dataList"
        height="90%"
        :header-cell-style="{
          'text-align': 'center',
          background: 'rgb(245, 245, 245)',
        }"
        :cell-style="{ 'text-align': 'center' }"
        :span-method="spanMethods"
        v-loading="loading"
      >
        <el-table-column
          label="商户姓名"
          prop="merchants_name"
        ></el-table-column>
        <el-table-column label="商户号" prop="merchants_number">
          <template slot-scope="{ row }">{{
            row.merchants_number ? row.merchants_number : "/"
          }}</template>
        </el-table-column>
        <el-table-column label="合同编码" show-overflow-tooltip>
          <template slot-scope="{ row }">{{
            row.contract_no ? row.contract_no : "/"
          }}</template>
        </el-table-column>
        <el-table-column
          label="商铺号"
          width="200px"
          show-overflow-tooltip
          prop="merchants_stall_name"
        >
          <template slot-scope="{ row }">{{
            row.merchants_stall_name ? row.merchants_stall_name : "/"
          }}</template>
        </el-table-column>
        <el-table-column
          v-for="(item, index) in billKeyList"
          :key="index"
          :label="`${item}(元)`"
          prop="water_charge"
        >
          <template slot-scope="{ row }">{{
            row.other_charge_list[item] ? row.other_charge_list[item] : "0"
          }}</template>
        </el-table-column>
        <el-table-column label="总费用(元)">
          <template slot-scope="{ row }">
            <span>{{ calc(row) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="合同状态">
          <template slot-scope="{ row }">
            <span>{{ row.contract_status | constatus }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="{ row }">
            <pop
              tips="详情"
              :accessid="87177"
              @myclick="
                $openNewTab(
                  `daybilldetail?merchants_id=${row.merchants_id}&bill_key=20&stalls=${row.merchants_stall_name}`
                )
              "
            >
              <div class="slot-box">
                <img class="icon" src="../../../unit/img/xq.png" alt />
              </div>
            </pop>
          </template>
        </el-table-column>
      </el-table>
      <div style="color: red; margin-top: 5px">
        注：日常费用账单数据来源于商户日常使用
      </div>
      <div class="public-page">
        <el-pagination
          :total="total"
          :current-page="form.page"
          :page-size="form.limit"
          layout="total,prev,pager,next"
          next-text="下一页"
          prev-text="上一页"
          @current-change="getList"
        ></el-pagination>
      </div>
    </div>

    <!-- 批量初始化 -->
    <el-dialog
      :title="importType == 0 ? '批量商铺初始化' : '手工抄表数据批量导入'"
      destroy-on-close
      @close="clearImportData"
      :visible.sync="initVisible"
      :close-on-click-modal="false"
    >
      <el-form ref="importForm">
        <el-form-item label="数值导入">
          <el-row>
            <el-col :span="2">
              <el-upload
                action
                :auto-upload="false"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                :on-change="fileChange"
              >
                <el-button type>上传文件</el-button>
              </el-upload>
            </el-col>
            <el-col :span="3" :offset="4">
              <el-button @click="downTemplate">下载模板</el-button>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item>
          <span
            >注：导入每个商铺数据必须与实际商铺数值一一对应，否则会导致经济纠纷。</span
          >
        </el-form-item>
      </el-form>
      <template slot="footer">
        <MyButton @click="initVisible = false">取消</MyButton>
        <MyButton type="primary" style="margin-left: 15px" @click="imported"
          >导入</MyButton
        >
      </template>
    </el-dialog>
    <!-- 商户shangp初始化 -->
    <el-dialog
      title="商户商铺初始化"
      destroy-on-close
      @close="merchantInitclose"
      :visible.sync="merchantInitvisible"
      :close-on-click-modal="false"
    >
      <el-form label-position="right" label-width="100px">
        <el-form-item label="商户姓名:">
          <el-select
            v-model="merchantInitForm.merchantName"
            class="label-width"
            placeholder
            @change="merchantselect"
          >
            <el-option
              v-for="item in merchantsList"
              :key="item.merchants_id"
              :value="item.merchants_id"
              :label="item.contact_name"
            ></el-option>
          </el-select>
        </el-form-item>
        <template v-if="selectList">
          <el-form-item label="身份证号:">
            <MyInput
              placeholder="身份证号"
              disabled
              class="label-width"
              v-model="selectList.merchants_card_id_original"
            ></MyInput>
          </el-form-item>
          <el-form-item
            v-for="item in selectList.stall"
            :key="item.contract_stall_id"
            :label="item.property_bill_list && item.merchants_stall_name"
          >
            <div class="fixedcharge" v-if="item.property_bill_list">
              <div
                class="item"
                v-for="items in item.property_bill_list"
                :key="items.bill_id"
              >
                <MyInput
                  class="label-width"
                  type="number"
                  v-model="items.old_key_number"
                  placeholder="请输入初始度数"
                  :disabled="items.usage_charge != 1"
                  posNumber
                >
                  <template slot="pre">{{ items.type_name }}:</template>
                </MyInput>
                <MyInput
                  class="label-width"
                  type="number"
                  placeholder="请输入附加费用"
                  :disabled="items.fixed_charges != 1"
                  style="margin-left: 15px"
                  v-model="items.fixed_price"
                  posNumber
                >
                  <template slot="pre">附加费:</template>
                </MyInput>
              </div>
            </div>
          </el-form-item>
        </template>
      </el-form>
      <template slot="footer">
        <MyButton @click="merchantInitvisible = false">取消</MyButton>
        <MyButton
          @click="merchantInited"
          style="margin-left: 15px"
          :disabled="!selectList || selectList.stall.length == 0"
          type="primary"
          >确认</MyButton
        >
      </template>
    </el-dialog>

    <!-- 商户shangp初始化---临时录入 -->
    <el-dialog
      title="商户商铺临时录入"
      destroy-on-close
      @close="merchantInitclose"
      :visible.sync="merchantCasualInitvisible"
      :close-on-click-modal="false"
    >
      <el-form label-position="right" label-width="100px">
        <el-form-item label="商户姓名:">
          <el-select
            v-model="merchantInitForm.merchantName"
            class="label-width"
            placeholder
            @change="merchantCasualSelect"
          >
            <el-option
              v-for="item in merchantsList"
              :key="item.merchants_id"
              :value="item.merchants_id"
              :label="item.contact_name"
            ></el-option>
          </el-select>
        </el-form-item>
        <template v-if="selectList">
          <el-form-item label="身份证号:">
            <MyInput
              placeholder="身份证号"
              disabled
              class="label-width"
              v-model="selectList.merchants_card_id_original"
            ></MyInput>
          </el-form-item>
          <el-form-item
            v-for="item in selectList.stall"
            :key="item.contract_stall_id"
            :label="item.property_bill_list && item.merchants_stall_name"
          >
            <div class="fixedcharge" v-if="item.property_bill_list">
              <div
                class="item"
                v-for="items in item.property_bill_list"
                :key="items.bill_id"
              >
                <MyInput
                  class="label-width"
                  type="number"
                  v-model="items.key_number"
                  placeholder="请输入起始值"
                  style="width: 50px"
                  :disabled="true"
                  posNumber
                >
                  <template slot="pre">起始值:</template>
                </MyInput>
                <MyInput
                  class="label-width"
                  type="number"
                  v-model="items.old_key_number"
                  placeholder="请输入截止值"
                  style="margin-left: 10px"
                  :disabled="items.usage_charge != 1"
                  posNumber
                >
                  <template slot="pre">{{ items.type_name }}:</template>
                </MyInput>
                <MyInput
                  class="label-width"
                  type="number"
                  placeholder="请输入附加费用"
                  :disabled="items.fixed_charges != 1"
                  style="margin-left: 10px"
                  v-model="items.fixed_price"
                  posNumber
                >
                  <template slot="pre">附加费:</template>
                </MyInput>
              </div>
            </div>
          </el-form-item>
        </template>
      </el-form>
      <template slot="footer">
        <MyButton @click="merchantCasualInitvisible = false">取消</MyButton>
        <MyButton
          @click="merchantCasualInited"
          style="margin-left: 15px"
          :disabled="!selectList || selectList.stall.length == 0"
          type="primary"
          >确认</MyButton
        >
      </template>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      navlist: [
        {
          title: "日常账单",
          url: "/merchantbill/daybill",
        },
        {
          title: "日常账单详细",
          path: "/merchantbill/daybill",
        },
      ],
      dataList: [],
      form: {
        merchants_name: "",
        merchants_number: "",
        merchants_stall_number: "",
        page: 1,
        limit: 10,
        pay_time_start: "",
        pay_time_end: "",
      },
      total: 0,
      date: [],
      initVisible: false,
      merchantInitvisible: false,
      merchantCasualInitvisible: false, //临时录入
      batchstallform: {
        file: "",
        tpl_type: 0,
      },
      importType: 0,
      merchantsList: [],
      merchantInitForm: {
        merchantName: "",
      },
      selectList: null,
      dailyBillOverview: null,
      loading: false,
      billKeyList: [],
      billKeyListLength: 0,
    };
  },
  filters: {
    constatus(e) {
      switch (e) {
        case 0:
          return "默认";
        case 10:
          return "正常";
        case 20:
          return "待审批";
        case 30:
          return "已拒绝";
        case 40:
          return "待商户签字";
        case 50:
          return "商户签字";
        case 60:
          return "商户签字-拒绝";
        case 70:
          return "即将到期";
        case 80:
          return "已到期";
        case 90:
          return "已续签";
      }
    },
  },
  created() {
    this.getList();
    this.getDailyBillOverview();
  },
  computed: {
    issubmit() {
      let value = false;
      let list = this.selectList ? this.selectList.stall : "";
      if (list) {
        list.forEach((item) => {
          if (item.property_bill_list) {
            item.property_bill_list.forEach((items) => {
              if (!items.old_key_number || !items.fixed_price) {
                value = true;
              } else {
                value = false;
              }
            });
          }
        });
      }
      return value;
    },
  },
  methods: {
    // 出账提示
    getDailyBillOverview() {
      this.$request.HttpGet("/bill/dailyBillOverview").then((res) => {
        if (res.data) {
          this.dailyBillOverview = res.data;
          if (res.data.have_bill > 0) {
            this.$myconfirm("请确认账单信息是否正常", "出账确认").then(() => {
              this.gourl("chargeoff");
            });
          }
        }
      });
    },
    changeDate(date) {
      if (date) {
        this.form.pay_time_start = date[0];
        this.form.pay_time_end = date[1];
      } else {
        this.form.pay_time_start = "";
        this.form.pay_time_end = "";
      }
    },
    getList(e = 1) {
      this.loading = true;
      this.dataLis = [];
      this.form.page = e;
      this.$request
        .HttpGet("/bill/dailyList", this.form)
        .then((res) => {
          this.loading = false;

          if (res.data && res.data.list) {
            this.billKeyList = res.data.billKeyList;
            this.billKeyListLength = this.billKeyList.length;
            let arr = [];
            res.data.list.forEach((item) => {
              if (item.contract) {
                item.contract.forEach((items, indexs) => {
                  if (indexs == 0) {
                    items.row = item.contract.length;
                  } else {
                    items.row = 0;
                  }
                  arr.push({
                    ...item,
                    ...items,
                  });
                });
              } else {
                item.row = 1;
                arr.push({
                  ...item,
                });
              }
            });
            this.dataList = arr;
            this.total = res.data.total;
          }
        })
        .catch(() => {
          this.loading = false;
          this.dataLis = [];
        });
    },
    spanMethods({ row, columnIndex }) {
      if (columnIndex == 0) {
        if (row.row > 0) {
          return [row.row, 1];
        }
        return [0, 0];
      }
    },
    calc(row) {
      let numarr = Object.values(row.other_charge_list);
      let total = numarr.reduce((total, item) => {
        return (total += Number(item));
      }, 0);
      return total.toFixed(2);
    },
    fileChange(file) {
      this.batchstallform.file = file;
    },
    setImport(type) {
      this.importType = type;
      this.initVisible = true;
    },
    async merchantStallInit() {
      let { data: res } = await this.$request.HttpGet("/bill/dailyInitList");

      this.merchantsList = res ?? [];
      this.merchantInitvisible = true;
    },
    async merchantStallCasualInit() {
      let { data: res } = await this.$request.HttpGet(
        "/bill/dailyCasualInitList"
      );

      this.merchantsList = res ?? [];

      this.merchantCasualInitvisible = true;
    },

    // 商户选择
    merchantselect(e) {
      let merchant_id = this.merchantsList.filter((item) => {
        return item.merchants_id == e;
      });
      console.log(merchant_id);
      this.selectList = {
        merchants_id: e,
        stall: [],
        merchants_card_id_original: merchant_id[0].merchants_card_id_original,
      };
      this.$request
        .HttpPost("/bill/dailyInitBillList", { merchants_id: e })
        .then((res) => {
          if (res.data) {
            let data = res.data;
            let keys = Object.keys(data);
            keys.forEach((item) => {
              this.selectList.stall.push({
                merchants_stall_name: item,
                property_bill_list: Object.values(data[item]),
              });
            });
            console.log(this.selectList);
          }
          // console.log();
        });
      // let arr = this.merchantsList.filter(item => {
      //   return item.merchants_id == e;
      // });
      // this.selectList = arr[0];
    },

    // 商户选择--临时
    merchantCasualSelect(e) {
      let merchant_id = this.merchantsList.filter((item) => {
        return item.merchants_id == e;
      });
      console.log(merchant_id);
      this.selectList = {
        merchants_id: e,
        stall: [],
        merchants_card_id_original: merchant_id[0].merchants_card_id_original,
      };
      this.$request
        .HttpPost("/bill/dailyCasualInitBillList", { merchants_id: e })
        .then((res) => {
          if (res.data) {
            let data = res.data;
            let keys = Object.keys(data);
            keys.forEach((item) => {
              this.selectList.stall.push({
                merchants_stall_name: item,
                property_bill_list: Object.values(data[item]),
              });
            });
            console.log(this.selectList);
          }
          // console.log();
        });
      // let arr = this.merchantsList.filter(item => {
      //   return item.merchants_id == e;
      // });
      // this.selectList = arr[0];
    },
    merchantInited() {
      this.$request
        .HttpPost("/bill/dailyInitEdit", this.selectList)
        .then((res) => {
          this.$common.notifySuccess(res.msg);
          this.merchantInitvisible = false;
        });
    },

    //临时录入
    merchantCasualInited() {
      //未判断截止用量必须>=起始值
      this.$request
        .HttpPost("/bill/dailyCasualInitEdit", this.selectList)
        .then((res) => {
          this.$common.notifySuccess(res.msg);
          this.merchantCasualInitvisible = false;
        });
    },

    downTemplate() {
      let url = "/bill/dailyInitImportTemplate";
      let name = "";
      if (this.importType == 1) {
        url = "/bill/dailyNowImportTemplate";
        name = "日常账单截至值模板";
      } else {
        // 日常费用初始化模板
        url = "/bill/dailyInitImportTemplate";
        name = "日常费用初始化模板";
      }
      this.$myconfirm(
        "请根据下载的模板格式录入数据，不能随意修改模板格式，否则将导致上传文件无法使用!"
      ).then(() => {
        this.$exported(url, {}, `${name}.xlsx`);
      });
    },
    // im=
    imported() {
      if (!this.batchstallform.file) {
        return this.$rmessage("请上传文件", "error");
      }
      let url = "";
      if (this.importType == 1) {
        url = "/bill/dailyNowImport";
      } else {
        // 日常费用初始化模板
        url = "/bill/dailyInitImport";
      }
      let form = new FormData();
      form.append("file", this.batchstallform.file.raw);
      this.$request.HttpPost(url, form).then((res) => {
        this.$common.notifySuccess(res.msg);
        this.initVisible = false;
        this.getList();
      });
    },
    clearImportData() {
      this.batchstallform.file = "";
      this.$refs.importForm.resetFields();
    },
    exported() {
      this.$exported("/bill/dailyExport", this.form, "日常费用账单.xlsx");
    },
    gourl(url) {
      this.$router.push(url);
    },
    merchantInitclose() {
      this.merchantInitForm.merchantName = "";
      if (this.selectList) {
        this.selectList.merchants_card_id_original = "";
        this.selectList.stall.property_bill_list = [];
        this.selectList = "";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.label-width {
  width: 230px;
}
.fixedcharge {
  .item {
    margin-bottom: 15px;
  }
}
.ysall {
  width: 160px;
  height: 32px;
  background: #02ce80;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  padding: 0 10px;
  box-sizing: border-box;
  line-height: 32px;
  span {
    &:nth-child(2) {
      font-weight: bold;
      margin-left: 12px;
    }
  }
}
</style>
